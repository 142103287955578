<template>
  <div class="cart-contain container mt-3 px-0"
    :class="$route.name == 'myOrders' ? (width == 'sm' ? 'mx-1' : 'mx-9') : ''">
    <div class="cart-header row mt-0">
      <div class="col-12 px-0">
        <div class="row" v-if="!loggedIn">
          <div class="col-12 px-0 mb-lg-3 mb-1 ">
            <div :class="f_ar">
              <i class="fa fa-credit-card-alt mx-1"></i> {{ $t("myOrders") }} ({{ myOrders ? myOrders.length : 0 }})
            </div>
          </div>
        </div>
        <div class="row" v-if="myOrders.length > 0">
          <div class="col-12 px-0 col-lg-5 mx-auto" v-for="order in renderOrders" :key="order.id">
            <div class="mainBody-card my-2" :class="isCanceled(order.id) ? 'mainBody-card-red' : ''">
              <div class="opt-label" :class="t_ar"> {{ $t("orderNumber") }} <span class="" :class="f_ar_reverse">{{
                  order.id
              }}</span>
              </div>
              <div class="opt-label" :class="t_ar"> {{ $t("orderDate") }} <span class="" :class="f_ar_reverse">{{
                  order.date
              }}</span>
              </div>
              <div class="opt-label" :class="t_ar"> {{ $t("orderTime") }} <span class="" :class="f_ar_reverse">{{
                  order.time
              }}</span>
              </div>
              <div class="row d-flex justify-content-between" :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row'">
                <button class="btn-pri mt-2 mx-0" @click="openModal(order.id)">
                  <i class="fa fa-info"></i> {{ $t("details") }} </button>
                <span class="canceled" v-if="isCanceled(order.id)">{{ $t("canceled") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row mt-0">
          <NoPlaces class="col-12 px-0" :msg="$t('noOrders')" :imgWith="true"
            :img="require('../../assets/images/empty.png')" />
        </div>
      </div>
    </div>
    <div class="modal fade pb-9" :class="width == 'sm' ? 'mx-1' : ''" tabindex="-1" id="orderModal">
      <div class=" modal-dialog" :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']">
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <OrderDetails :item="order" :cancelOrder="cancelOrderClicked" :isCanceled="isCanceled"
              v-if="!modalLoading && order" />
            <NoPlaces v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import NoPlaces from "@/components/misc/NoPlaces";
import OrderDetails from "@/components/order/OrderDetails";

export default {
  data() {
    return {
      page: 1,
    };
  },
  components: {
    NoPlaces,
    OrderDetails,
  },
  mounted() {
    this.scroll();
    this.getMyOrders()
      .then()
      .catch((err) => console.log(err));
    this.getAllCanceled();
    if (this.$route.params.id && this.$route.params.id != false) {
      this.openModal(this.$route.params.id);
      this.$route.params.id = false;
    }
  },
  computed: {
    ...mapGetters([
      "myOrders",
      "order",
      "width",
      "canceled",
      "loading",
      "modalLoading",
      "loggedIn",
    ]),
    renderOrders() {
      if (this.page * 10 > this.myOrders.length) {
        return this.myOrders.slice(0, this.myOrders.length);
      } else return this.myOrders.slice(0, this.page * 10);
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
  methods: {
    ...mapActions(["getMyOrders", "getOrder", "cancelOrder", "getAllCanceled"]),
    ...mapMutations(["showMsg"]),
    openModal(id) {
      $("#orderModal").modal("show");
      this.getOrder(id);
    },
    cancelOrderClicked(id) {
      this.showMsg({
        close: true,
        text: this.$t("sureCancel"),
        type: "warning",
        condition: 6,
        orderId: id,
      });
      // this.cancelOrder(id)
      //   .then(res => {
      //     $('.modal').modal('hide');
      //     this.showMsg({
      //       text: 'Order Has been Canceled Successfully',
      //       type: 'success',
      //     });
      //     this.getAllCanceled();
      //   })
    },
    isCanceled(id) {
      return this.canceled.find((el) => el == id) ? true : false;
    },
    nextPage() {
      this.page++;
      // console.log(this.page * 10, this.myOrders.length)
    },
    scroll() {
      window.onscroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          this.nextPage();
        }
      };
    },
  },
};
</script>