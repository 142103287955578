<template>
  <div class="container">
    <div class="row" :dir="locale == 'ar' ? 'rtl' : ''">
      <!-- Start the router -->
      <router-view class="" />
    </div>
  </div>
</template>

<script>
export default {
  // mounted() {
  //   this.$loadScript('../../../js/bootstrap.bundle.js');
  // },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>